.container {
  font: var(--code-font);
  margin: 60px auto;
  grid-column: 3 / 10;
}

.code {
  word-break: break-all;
  white-space: pre-wrap;
}

@media only screen and (max-width: 478px) {
  .container {
    grid-column: 1/-1;
  }
}
