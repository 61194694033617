@font-face {
  font-family: "rules-variable";
  src: url("/public/fonts/RulesVariable.ttf") format("truetype");
  font-weight: 200 900;
  font-display: swap;
}

@font-face {
  font-family: "input-mono";
  src: url("/public/fonts/InputMono.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
}

body * {
  box-sizing: border-box;
}

body {
  color: var(--case-study-text-color);
  margin: 0;
  padding: 0;
  font-family: "rules-variable", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--case-study-page-background-color);
}

h1 {
  margin: 0;
  padding: 0;
  font-size: 42px;
  font-weight: 600;
  line-height: 130%;
}

h2 {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 300;
  line-height: 130%;
}

h3 {
  margin: 0;
  padding: 0;
  font-size: 29px;
  font-weight: 600;
  line-height: 130%;
}

h4 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 300;
  line-height: 130%;
}

p {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 300;
  line-height: 140%;
}

b {
  font-weight: 500;
}

a {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 300;
  line-height: 140%;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

body {
  padding: 0;
  margin: 0;
}

:root {
  --page-max-width: 1280px;
  --code-font: 13px/150% "input-mono";
  --blue: #1646d4;
  --about-background-color: var(--blue);
  --light-grey: #f6f6f6;
  --dark-grey: #1f344d;
  --case-study-page-background-color: var(--light-grey);
  --case-study-text-color: var(--dark-grey);
}
