.navBar {
  position: sticky;
  top: 0;
  padding: 8px;
  background-color: var(--light-grey);
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.navBar button {
  color: var(--dark-grey);
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 17px;
}

.navBar button:hover {
  text-decoration: underline;
  cursor: pointer;
}
