.container {
  margin: 120px auto 60px auto;
  row-gap: 0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-column: 1/7;
}

.heading {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.role {
  font-weight: 500;
}

.tags {
  grid-column: -1 / -4;
  grid-row: 2/3;

  text-align: end;
}

@media only screen and (max-width: 478px) {
  .container {
    margin: 80px auto 20px auto;
    row-gap: 40px;
  }

  .tags {
    grid-column: -1 / -4;
    grid-row: 2/3;
  }
}
