.project {
  grid-column: span 4;
  text-decoration: none;
  color: var(--dark-grey);
  transition: 0.2s ease-in;
  padding: 8px 8px;
  --hover-color: initial;
}

.projectInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 0;
}

.projectDetails {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 8px 0;
}

.title {
  font-weight: 600;
}

.projectTitle {
  padding: 8px 0;
  border-bottom: 1px solid var(--dark-grey);
  display: flex;
}

img {
  width: 100%;
  height: auto;
}

.project:hover {
  text-decoration: none;
  background-color: var(
    --hover-color);
}

@media only screen and (max-width: 767px) {
  .project {
    grid-column: span 6;
  }
  img {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 478px) {
  .project {
    grid-column: 1/-1;
  }

  .scrollBg {
    background-color: var(--hover-color);
  }
}
