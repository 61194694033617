.container {
  max-width: var(--page-max-width);
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 8px;
  margin: 0 auto;
}

@media only screen and (max-width: 478px) {
  .container {
    padding: 0 8px;
    grid-template-columns: repeat(6, 1fr);
    gap: 8px;
  }
}
