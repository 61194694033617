.header {
  padding: 16px 12px;
}

.projectContainer {
  max-width: var(--page-max-width);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin: 0 auto;
}

.about {
  margin: 80px 0;
  grid-column: 1/10;
}

.footer {
  margin-top: 80px;
  padding: 16px 12px;
}

.copyright {
  font-size: 15px;
}
