.links {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  row-gap: 12px;
}

.link {
  display: flex;
}

.container {
  grid-column: 1 / -1;
}

.links * {
  color: var(--case-study-text-color);
}

@media only screen and (max-width: 478px) {
  .links {
    grid-column: 1 / -1;
  }
}
