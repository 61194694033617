.container {
  margin: 60px auto;
  row-gap: 0;
}

.container * {
  margin-bottom: 16px;
}

.content {
  grid-column: 3 / 10;
}

@media only screen and (max-width: 478px) {
  .content {
    grid-column: 1/-1;
  }
}
